import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../../Layout';
import Meta from '../basics/Meta';

import Header from '../basics/header';
import Quote from '../basics/quote';
import Blogs from '../basics/blogs';
import Insights from '../basics/insights';
import BorderColumns from '../basics/border-columns';
import Spacer from '../basics/spacer';
import Contact from '../basics/contact';
import GrayContent from '../basics/gray-content';

const ServiceTemplate = ({ data }) => {
  const pageData = data.digitalMarketingPageHJson;
  return (
    <Layout useClass="services-layout">
      <Meta
        title={pageData.Meta.title}
        desc={pageData.Meta.desc}
        ogTitle={pageData.Meta.ogTitle}
        ogDesc={pageData.Meta.ogDesc}
        ogImg={pageData.Meta.ogImg}
      />
      <Header
        title={pageData.Header.title}
        subTitle={pageData.Header.subTitle}
        bgImage={pageData.Header.bgImage}
        hexLettersImage={pageData.Header.hexLettersImage.publicURL}
        subTextHtml={pageData.Header.subTextHtml}
      />
      <Spacer height="82" />
      <BorderColumns
        col="2"
        outerTitle={pageData.BorderColumns.title}
        subTitle={pageData.BorderColumns.subTitle}
        data={pageData.BorderColumns.data}
      />
      <Spacer height="18" />
      <Quote image={pageData.Quote.image.publicURL} text={pageData.Quote.text} author={pageData.Quote.author} />
      <Spacer height="16" />
      <GrayContent title={pageData.How.title} text={pageData.How.text} />
      <Insights insights={pageData.Insights.insights} title={pageData.Insights.title} />
      <Contact title={pageData.Contact.title} text={pageData.Contact.text} />
      <Blogs title={pageData.Blogs.title} blogPosts={data.allWpPost.edges} />
    </Layout>
  );
};
export default ServiceTemplate;

export const pageQuery = graphql`
  query ($catSlug: String!) {
    allWpPost(
      limit: 3
      filter: { categories: { nodes: { elemMatch: { slug: { eq: $catSlug } } } } }
      sort: { fields: [date], order: DESC }
    ) {
      edges {
        node {
          id
          title
          slug
          seo {
            title
            metaDesc
          }
          featuredImage {
            node {
              sourceUrl
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(layout: FIXED, width: 360, height: 156, transformOptions: { cropFocus: CENTER })
                }
              }
            }
          }
        }
      }
    }

    digitalMarketingPageHJson {
      Meta {
        title
        desc
        ogTitle
        ogDesc
        ogImg
      }
      Blogs {
        title
      }
      BorderColumns {
        title
        subTitle
        data {
          html
          linkPath
          linkText
          title
        }
      }
      Contact {
        text
        title
      }
      Header {
        subTextHtml
        subTitle
        title
        bgImage {
          childImageSharp {
            gatsbyImageData(
              breakpoints: [500, 750, 1440, 1920, 2400]
              layout: FULL_WIDTH
              quality: 80
              placeholder: NONE
            )
          }
        }
        hexLettersImage {
          publicURL
        }
      }
      Quote {
        author
        image {
          publicURL
        }
        text
      }
      Insights {
        title
        insights {
          label
          number
          suffix
          prefix
          source
        }
      }
      How {
        title
        text
      }
    }
  }
`;
