import React, { useEffect } from 'react';
import styled from 'styled-components';

const GrayContent = styled.div`
  max-width: 100%;
  width: 100%;
  padding-top: 126px;
  padding-bottom: 129px;
  padding-right: 40px;
  padding-left: 40px;
  background-color: #f2f2f2;
  .wrap-subTextHtml {
    max-width: 920px;
    width: 100%;
    margin: 0 auto;
    h2 {
      font-family: Oswald;
      font-size: 32px;
      font-weight: 300;
      line-height: 36px;
      text-align: center;
      color: #000;
      margin-top: 0;
      margin-bottom: 20px;
    }
    .subTextHtml {
      font-family: Roboto;
      font-size: 18px;
      font-weight: 400;
      line-height: 36px;
      letter-spacing: 0.02em;
      text-align: left;
      color: #000;
      margin-top: 20px;
      margin-bottom: 0;
    }
  }
`;

function grayContent(props) {
  return (
    <>
      <GrayContent>
        {props.text ? (
          <div className="wrap-subTextHtml">
            {props.title ? <h2>{props.title}</h2> : ''}
            <div className="subTextHtml" dangerouslySetInnerHTML={{ __html: props.text }}></div>
          </div>
        ) : (
          ''
        )}
      </GrayContent>
    </>
  );
}

export default grayContent;
